import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { BlogStyles } from './BlogStyles.js'

const components = {
    h1: BlogStyles.h1,
    h2: BlogStyles.h2,
    h3: BlogStyles.h3,
    p: BlogStyles.p,
    ul: BlogStyles.ul
}

export const wrapRootElement = ({ element }) => {
    return (
      <MDXProvider components={components}>
        {element}
      </MDXProvider>
    )
}
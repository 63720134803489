import React from 'react'

export const BlogStyles = {
    h1: props => (
        <h1 style={{
            color: '#374140',
            marginBottom: '1rem'
        }} {...props} />
    ),
    h2: props => (
        <h2 style={{
            color: '#374140',
            marginBottom: '1rem'
        }} {...props} />
    ),
    h3: props => (
        <h3 style={{
            color: '#374140',
            marginBottom: '1rem'
        }} {...props} />
    ),
    p: props => (
        <p style={{
            color: '#374140',
            marginBottom: '2rem',
            lineHeight: '2rem'
        }} {...props} />
    ),
    ul: props => (
        <ul style={{
            color: '#374140',
            marginBottom: '1rem'
        }} {...props} />
    )

}

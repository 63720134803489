exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projectassesment-js": () => import("./../../../src/pages/projectassesment.js" /* webpackChunkName: "component---src-pages-projectassesment-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-post-js-content-file-path-codebuild-output-src-897646365-src-jarihillukkala-content-blog-how-many-hours-does-it-take-to-redesign-a-website-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/codebuild/output/src897646365/src/jarihillukkala/content/blog/how-many-hours-does-it-take-to-redesign-a-website.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-codebuild-output-src-897646365-src-jarihillukkala-content-blog-how-many-hours-does-it-take-to-redesign-a-website-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-codebuild-output-src-897646365-src-jarihillukkala-content-blog-what-makes-a-good-construction-company-website-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/codebuild/output/src897646365/src/jarihillukkala/content/blog/what-makes-a-good-construction-company-website.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-codebuild-output-src-897646365-src-jarihillukkala-content-blog-what-makes-a-good-construction-company-website-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-codebuild-output-src-897646365-src-jarihillukkala-content-blog-what-makes-a-good-restaurant-website-mdx": () => import("./../../../src/templates/BlogPost.js?__contentFilePath=/codebuild/output/src897646365/src/jarihillukkala/content/blog/what-makes-a-good-restaurant-website.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-codebuild-output-src-897646365-src-jarihillukkala-content-blog-what-makes-a-good-restaurant-website-mdx" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/CaseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */)
}

